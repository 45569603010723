import "./index.css";

import * as serviceWorker from "./serviceWorker";

import CssBaseline from "@material-ui/core/CssBaseline";
import React from "react";
import ReactDOM from "react-dom";
import { RecoilRoot } from "recoil";
import { SnackbarProvider } from "notistack";
import configureFirebase from "configureFirebase";

configureFirebase().then(async () => {
  const App = await import("./App");
  ReactDOM.render(
    <RecoilRoot>
      <SnackbarProvider
        maxSnack={5}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <CssBaseline />
        <App.default />
      </SnackbarProvider>
    </RecoilRoot>,
    document.getElementById("root")
  );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
