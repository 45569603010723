import "firebase/auth";
import "firebase/firestore";

import firebase from "firebase/app";

const configureFirebase = async () => {
  const response = await fetch("/__/firebase/init.json");
  const config = await response.json();
  firebase.initializeApp(config);
};
export default configureFirebase;
